/* Core CSS */
body {
  overflow: hidden;

}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-bottom: 0;
  font-family: 'Play', sans-serif;
  letter-spacing: 1px;
}


#blocker {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.webgl {
  position: fixed;
  /* Important */
  top: 0;
  left: 0;
  outline: none;

  cursor: move;
  /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.webgl:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.text-justify {
  text-align: justify;
}

/* Annotation */
.point {
  position: absolute;
  top: 50%;
  left: 50%;
  /* pointer-events: none; */
}

.point .label {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 80px;
  height: 40px;
  border-radius: 50%;
  background: #00000077;
  border: 1px solid #ffffff77;
  color: #ffffff;
  font-family: Helvetica, Arial, sans-serif;
  text-align: center;
  line-height: 40px;
  font-weight: 100;
  font-size: 14px;
  cursor: pointer;
  transform: scale(0, 0);
  transition: transform 0.3s;
}

.point .text {
  position: absolute;
  top: 30px;
  left: -120px;
  width: 200px;
  padding: 20px;
  border-radius: 4px;
  background: #00000077;
  border: 1px solid #ffffff77;
  color: #ffffff;
  line-height: 1.3em;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 100;
  font-size: 14px;
  opacity: 0;
  transition-duration: opacity 0.3s;
  pointer-events: none;
}

.point:hover .text {
  opacity: 1;
}

.point.visible .label {
  transform: scale(1, 1);
}

/* Interior Preloader */
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 100%;
  z-index: 1000;
  background: url(../static/img/bg/preloader-bg.jpg) no-repeat center;
  /* background: rgb(10,24,40);
  background: linear-gradient(0deg, rgba(10,24,40,1) 0%, rgba(24,46,69,1) 41%, rgba(30,54,80,1) 46%, rgba(24,46,69,1) 51%, rgba(10,24,40,1) 100%); */
  background-size: cover;
  transition: all 0.5s ease-out;
}

.overlay.show {
  left: 0;
}

.overlay-content {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  align-items: end;

  position: absolute;
  width: 100%;
  height: 100%;
  /*top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.overlay-content img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
  margin-bottom: 8vh;
}

/* Pop Up Modal Style */
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999999999;
}

.popup-wrapper {
  position: relative;
  width: 500px;
  height: 450px;
  max-width: 500px;
  max-height: 450px;

  /* overflow-y: scroll; */
  background-color: #fff;
  /* padding: 3rem 1.5rem; */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
}

.popup-wrapper img.z-logo {
  width: 120px;
  margin-top: 0.25rem;
  z-index: 9999999;
}

.popup-wrapper img {
  width: 200px;
}

.popup-content {
  width: 100%;
  max-width: 500px;
  max-height: 450px;
}

.popup-body {
  overflow-y: scroll;
  margin: 0 1rem;
  padding: 1.6rem 2rem;
  max-height: 335px;
}

.popup-wrapper button {
  text-transform: uppercase;
  padding: 8px 20px;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.popup-wrapper .btn-zcity {
  background-color: #1277bd;
}
.popup-wrapper .btn-zcity:hover {
  background-color: #24385c;
}
.popup-wrapper .btn-tazte {
  background-color: #f8ae16;
}
.popup-wrapper .btn-tazte:hover {
  background-color: #ef901e;
}
.popup-wrapper .btn-enogy {
  background-color: #49c0c9;
}
.popup-wrapper .btn-enogy:hover {
  background-color: #43a9b3;
}


/* Pop Up Modal ( Content Header & Footer ) */
.popup-header {
  width: 100%;
  height: 100px;
  max-width: 500px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.popup-header.zcity-style {
  background-image: linear-gradient(45deg, rgb(18, 119, 189), rgb(36, 56, 92));
}
.popup-header.tazte-style {
  background-image: linear-gradient(45deg, rgb(247 181 22), rgb(235 123 34));
}
.popup-header.enogy-style {
  background-image: linear-gradient(45deg, rgb(73 192 201), rgb(72 214 225));
}

.popup-header:before {
  position: absolute;
  content: "";
  top: 65px;
  left: 0;
  right: 0;
  height: 34px;
  border-top-right-radius: 200px 80%;
  border-top-left-radius: 200px 80%;
}
.popup-header.zcity-style:before {
  background-image: linear-gradient(90deg, #e1b533, #1277bd, #24385c);
}
.popup-header.tazte-style:before {
  background-image: linear-gradient(90deg, rgb(249 236 211), rgb(250 177 23), rgb(242 158 72));
}
.popup-header.enogy-style:before {
  background-image: linear-gradient(90deg, rgb(247 204 33), rgb(255 240 178), rgb(91 230 241), rgb(73 192 201));
}

.popup-header:after {
  background: white;
  position: absolute;
  content: "";
  top: 70px;
  left: 0;
  right: 0;
  height: 30px;
  border-top-right-radius: 200px 100%;
  border-top-left-radius: 200px 100%;
}

.popup-footer {
  width: 100%;
  height: 15px;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.popup-footer.zcity-footer {
  background-image: linear-gradient(45deg, rgb(18, 119, 189), rgb(36, 56, 92));
  border-top: 2px solid rgb(34 63 102 / 50%);
}
.popup-footer.tazte-footer {
  background-image: linear-gradient(90deg, rgb(255 213 133), rgb(250 177 23), rgb(242 158 72));
  border-top: 2px solid rgb(246 185 24 / 50%);
}
.popup-footer.enogy-footer {
  background-image: linear-gradient(90deg, rgb(73 192 201), rgb(72 214 225));
  border-top: 2px solid rgb(73 192 201 / 50%);
}

/* Modal Scroll */
/* Width */
.popup-body::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.popup-body::-webkit-scrollbar-track {
  margin: 1rem 0;
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
.popup-body::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  /* background-clip: padding-box; */
}

/* Handle On Hover */
.popup-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Scroll Btn */
/* .popup-body::-webkit-scrollbar-button:single-button {
  background-color: #dedede;
  height: 10px;
  border-style: solid;
} */
/* Up */
/* .popup-body::-webkit-scrollbar-button:single-button:vertical:decrement {
  background-position: center 2px;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent #555 transparent;
}
.popup-body::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  border-color: transparent transparent #777 transparent;
}
.popup-body::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  border-color: transparent transparent #555 transparent;
} */
/* Down */
/* .popup-body::-webkit-scrollbar-button:single-button:vertical:increment {
  background-position: center 2px;
  border-width: 4px 4px 0 4px;
  border-color: #555555 transparent transparent transparent;
}
.popup-body::-webkit-scrollbar-button:vertical:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
} */



@media screen and (max-width: 768px) {
  /* Interior Preloader */
  .overlay-content img {
    margin-bottom: 15vh;
  }

  /* Modal Content */
  .popup-wrapper {
    max-width: 360px;
    /* padding: 6.5rem 1.5rem 5rem 1.5rem; */
  }
  /* Pop Up Modal ( Content Header & Footer ) */
  .popup-header:after {
    top: 71px;
  }
  /* Modal Scroll */
  /* Width */
  .popup-body::-webkit-scrollbar {
    width: 5px;
  }

}