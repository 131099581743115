/* Menu Navigation Bar */
#menuToggle {
  display: block;
  position: fixed;
  top: 2rem;
  right: 1.5rem;
  z-index: 888;

  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  color: white
}

#menuToggle a:hover {
  color: #293f67;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 40px;
  position: absolute;
  top: -11px;
  right: -9px;
  cursor: pointer;

  opacity: 0;
  /* hide this */
  z-index: 999;
  /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
 * Just a quick hamburger
 */
#menuToggle span {
  display: block;
  width: 28px;
  height: 3px;
  position: relative;

  background: #fff;
  box-shadow: 1px 1px 1px rgb(0 0 0 / 20%);
  border-radius: 2px;
  margin-bottom: 6px;
  z-index: 888;

  transform-origin: -4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
    opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked~span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -4px);
  background: white !important;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked~span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked~span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu {
  position: absolute;
  width: 250px;
  height: 100vh;
  margin: -60px 0 0 -198px;
  padding: 30px 20px;
  padding-top: 125px;

  background: rgb(75, 130, 219, .8);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);

  /* border-left: 5px solid #144d7e; */
}

.btn.btn-primary  {
  color: white;
  background-color:  rgb(75, 130, 219);
  border-radius: 0.6rem;
  border: 1px solid  rgb(118 170 255);
  margin: 5px 0;
  width: 100%;

}

#menu li {
  padding: 0;
  font-size: smaller;
  vertical-align: middle;
}

#menu li h5 {
  margin: 5px auto;
}

.active {
  color: #757BFD;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked~ul {
  transform: none;
}

menu img {
  display: block;
  position: relative;
  left: auto;
  top: auto;
  max-width: 40%;
}
