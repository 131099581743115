#loadingscreen {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;

  background: url(../static/img/bg/3d-backgrond.jpg) no-repeat center;
  background-size: cover;
  z-index: 1000;
  color: white;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 100;
}

#loader-wrapper {
  position: absolute;
  width: 100%;
  max-width: 500px;
  top: 58%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  row-gap: 40px;
  text-align: center;
}

#progress {
  width: 100%;
  height: 60px;
  background-color: #034876;
  border-radius: 99px;
  overflow: hidden;
  position: relative;
  text-align: center;
  box-shadow: 2px 5px 15px 1px rgba(2,75,125,0.52);
  -webkit-box-shadow: 2px 5px 15px 1px rgba(2,75,125,0.52);
  -moz-box-shadow: 2px 5px 15px 1px rgba(2,75,125,0.52);
}

#progress-bar {
  width: 50%;
  height: 100%;
  background: #0177BF;
}

#loading {
  width: 100%;
  font-size: 1.1rem;
  letter-spacing: .5px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);

  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-55%, -55%);
}

/* Loading Btn */
.cta {
  width: 320px;
  height: 60px;
  font-size: 20px;
  background: #034876;
  color: white;
  border-radius: 99px;
}
.cta:hover {
  background: #1277bd;
  color: #fff;
}
.cta:active {
  background: #034876;
}

/* Fade-In Effect */
.visible {
  visibility: visible;
  opacity: 1;
  transition: opacity 0s linear;
}

/* Fade-Out Effect */
.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 1.0s, opacity 0.3s linear;
}

.title {
  font-size: 96px;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
  color: rgb(100, 68, 177);
}

@media only screen and (min-width: 1400px) {
  #loader-wrapper {
    max-width: 900px;
    top: 55%;
  }
}

@media screen and (max-width: 768px) {
  #loader-wrapper {
    padding: 0 2rem;
  }
}

@media only screen and (max-width: 600px) {
  .cta {
    width: 100%;
  }

  #progress {
    width: 100%;
  }
}
